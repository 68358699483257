
export const phrases = [
    {
        phrase: 'Haz del éxito un habito',
        author: 'José Luis Serna'        
    },    
    { 
        phrase: 'Un hombre, como regla general, debe muy poco a lo que ha nacido, un hombre es lo que hace de sí mismo.',
        author: 'Alexander Graham Bell'
    },
    { 
        phrase: 'Todos caemos al suelo en algún momento. Es la forma en que te levantas, ese es el verdadero desafío. ¿No es así?',
        author: 'Madonna'
    },
    { 
        phrase: 'Si no sabes, te enseño. Si no puedes, te ayudo. Pero si no quieres, lo siento, pero nada puedo hacer por ti.',
        author: 'Alexander Graham Bell'
    },
    { 
        phrase: 'Lo único que se interpone entre ti y tu sueño, es la voluntad de intentarlo y la creencia de que en realidad es posible',
        author: 'Joel Brown'
    },
    { 
        phrase: 'El éxito consiste en obtener lo que se desea. La felicidad, en disfrutar lo que se obtiene.',
        author: 'Henry Ford'
    },
    { 
        phrase: 'La única manera de hacer un gran trabajo, es amar lo que haces. Si no lo has encontrado, sigue buscando. No te conformes..',
        author: 'Steve Jobs'
    }, 
    { 
        phrase: 'Estoy agradecido por todos los que me dijeron NO. Es gracias a ellos estoy siendo yo mismo.',
        author: 'Albert Einstein'
    }, 
    { 
        phrase: 'El éxito llega para todos aquellos que están ocupados buscándolo.',
        author: 'Henry Thoreau'
    }, 
    { 
        phrase: 'Dondequiera que vayas, sin importar el clima, siempre lleva su propio sol. La actitud lo es todo.',
        author: 'D’Angelo'
    },
     { 
        phrase: 'Un viaje de mil millas comienza con un solo paso',
        author: 'Lao Tzu'
    }, 
    { 
        phrase: 'Si la oportunidad no llama, construye una puerta.',
        author: 'Milton Berle'
    },
    { 
        phrase: 'He fallado una y otra vez en mi vida. Esa es la razón principal de mi éxito',
        author: 'Michael Jordan'
    },
    { 
        phrase: 'Todo lo que quieres está ahí fuera esperando para que lo hagas. Todo lo que quieres también te quiere. Pero hay que tomar medidas para conseguirlo.',
        author: 'Jack Canfield'
    },
    { 
        phrase: 'No me gustan las etiquetas. Te limitan, y yo no quiero límites.',
        author: 'Madonna'
    },
    { 
        phrase: 'Cuando algo es lo suficientemente importante, lo haces incluso si las probabilidades de que salga bien no te acompañan ',
        author: 'Elon Musk'
    },
    {
        phrase: 'Cuéntamelo y me olvidaré. enséñamelo y lo recordaré. involúcrame y lo aprenderé',
        author: 'Benjamin Franklin'        
    },
    {
        phrase: 'Tu actitud, no tu aptitud, determinará tu altitud.',
        author: 'Zig Ziglar'        
    },
    {
        phrase: 'Un optimista ve una oportunidad en toda calamidad, un pesimista ve una calamidad en toda oportunidad',
        author: 'Winston Churchill'        
    },
    {
        phrase: 'Sal de tu zona de confort. Solo puedes crecer si estás dispuesto a sentirte raro e incómodo cuando intentas algo nuevo ',
        author: 'Brian Tracy'        
    },
    {
        phrase: 'Existe al menos un rincón del universo que con toda seguridad puedes mejorar, y eres tú mismo ',
        author: 'Aldous Huxley'        
    },    
  ]  
 
  